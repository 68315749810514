import $http from '../../utils/http.js';




// 个人信息
export function list(data){
    return $http.get("/User/list",data,'loadingDiv');
}

// 新建用户
export function add(data){
    return $http.post("/User/add",data,'loadingDiv');
}

// 停用用户
export function upd_status(data){
    return $http.post("/User/upd_status",data,'loadingDiv');

}
// 企业删除
export function e_u_details(data){
    return $http.post("/User/e_u_details",data,'loadingDiv');
}
// 套餐信息
export function package_info(data){
    return $http.get("/User/package_info",data,'loadingDiv');
}